import * as React from "react"
import { graphql } from "gatsby"
import { Layout } from "../../components/layout"
import { ProductListing } from "../../components/product-listing"
import { Seo } from "../../components/seo"
import { MoreButton } from "../../components/more-button"
import { title } from "./index.module.css"

export default function Shop({ data: { products } }) {
  return (
    <Layout>
      <Seo title="All Products" />
      <section className="w-full max-w-3xl mx-auto">
        <h1 className={title}>Shop</h1>
        <ProductListing products={products.nodes} />
        {products.pageInfo.hasNextPage && (
          <MoreButton to={`/search#more`}>More products</MoreButton>
        )}
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    products: allShopifyProduct(
      sort: { fields: publishedAt, order: ASC }
      limit: 24
      filter: { tags: { eq: "avgp" } }
    ) {
      nodes {
        ...ProductCard
        productType
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`
